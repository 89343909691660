import Home from "./containers/Home"
import DrivingMap from "./containers/DrivingMap"
import Location from "./components/Location"
import Finish from "./containers/Finish"

import CanopyLeaf from "./img/locations/CanopyLeaf.svg"
import Canopy1 from "./img/locations/Canopy1.jpg"
import Canopy2 from "./img/locations/Canopy2.jpg"
import Canopy3 from "./img/locations/Canopy3.jpg"

import ArborlyLeaf from "./img/locations/ArborlyLeaf.svg"
import Arborly1 from "./img/locations/Arborly1.jpg"
import Arborly2 from "./img/locations/Arborly2.jpg"
import Arborly3 from "./img/locations/Arborly3.jpg"
import Arborly4 from "./img/locations/Arborly4.jpg"

import CanvasFlower from "./img/locations/CanvasFlower.svg"
import Canvas1 from "./img/locations/Canvas1.jpg"
import Canvas2 from "./img/locations/Canvas2.jpg"
import Canvas3 from "./img/locations/Canvas3.jpg"
import Canvas4 from "./img/locations/Canvas4.jpg"

import RecCenter1 from "./img/locations/RecCenter1.jpg"
import RecCenter2 from "./img/locations/RecCenter2.jpg"
import RecCenter3 from "./img/locations/RecCenter3.jpg"
import RecCenter4 from "./img/locations/RecCenter4.jpg"

import EsplanadePlant from "./img/locations/EsplanadePlant.svg"
import Esplanade1 from "./img/locations/Esplanade-Westin7-Living.jpg"
import Esplanade2 from "./img/locations/Esplanade-Arise2-GreatRoom.jpg"
import Esplanade3 from "./img/locations/Esplanade-Arise3x-Exterior.jpg"
import Esplanade4 from "./img/locations/Esplanade-Westin4-RearPatio.jpg"

import EverviewFlower from "./img/locations/EverviewFlower.svg"
import Everview1 from "./img/locations/Everview1.jpg"
import Everview2 from "./img/locations/Everview2.jpg"
import Everview3 from "./img/locations/Everview3.jpg"
import Everview4 from "./img/locations/Everview4.jpg"

import OpenSpaces1 from "./img/locations/OpenSpaces1.jpg"
import OpenSpaces2 from "./img/locations/OpenSpaces2.jpg"
import OpenSpaces3 from "./img/locations/OpenSpaces3.jpg"

import SportsPark1 from "./img/locations/SportsPark1.jpg"
import SportsPark2 from "./img/locations/SportsPark2.jpg"
import SportsPark3 from "./img/locations/SportsPark3.jpg"

import TrailsTransition from "./img/locations/TrailFootage.mp4"
import TrailsTransitionPoster from "./img/locations/TrailsPoster.jpg"
import Trails1 from "./img/locations/Trails1.jpg"
import Trails2 from "./img/locations/OpenSpaces3.jpg"
import Trails3 from "./img/locations/Trails3.jpg"

import SommersPlaceVO from "./audio/SommersPlace.mp3"
import ArborlyModelHomesVO from "./audio/ArborlyModelHomes.mp3"
import CanopyModelHomesVO from "./audio/CanopyModelHomes.mp3"
import CanvasModelHomesVO from "./audio/CanvasModelHomes.mp3"
import EsplanadeModelHomesVO from "./audio/EsplanadeModelHomes.mp3"
import EsplanadeRecCenterVO from "./audio/EsplanadeRecCenter.mp3"
import EverviewModelHomesVO from "./audio/EverviewModelHomes.mp3"
import OpenSpacesVO from "./audio/OpenSpaces.mp3"
import SportsParkVO from "./audio/SportsPark.mp3"
import TrailsVO from "./audio/Trails.mp3"

const routes = [
  { path: "/", exact: true, title: "Home", component: Home },
  {
    path: "/map",
    // exact: true,
    title: "Map",
    component: DrivingMap,
    routes: [
      {
        x: 850,
        y: 550,
        path: "/map/sommers-Place",
        exact: true,
        title: "Sommers Place",
        component: Location,
        transition: "https://vimeo.com/538901066",
        background: "https://vimeo.com/538905364",
        audio: SommersPlaceVO,
        active: true,
      },
      {
        x: 750,
        y: 470,
        path: "/map/arborly",
        exact: true,
        title: "Arborly",
        subtitle: "by Richmond American Homes",
        isNeighborhood: true,
        neighborhoodInfo: {
          type: "ONE- AND TWO-STORY HOMES",
          sqft: "Approx. 1,940 – 2,940 Sq. Ft.",
          bedbath: "3 – 5 Beds, 3 – 3.5 Baths",
          moreInfoLink: "https://sommersbend.com/collections/arborly/",
        },
        component: Location,
        transitionImg: ArborlyLeaf,
        transitionBgColor: "#728835",
        backgroundImgs: [Arborly1, Arborly2, Arborly3, Arborly4],
        audio: ArborlyModelHomesVO,
        active: true,
      },
      {
        x: 750,
        y: 340,
        path: "/map/everview",
        exact: true,
        title: "Everview",
        subtitle: "by Woodside Homes",
        isNeighborhood: true,
        neighborhoodInfo: {
          type: "ONE- AND TWO-STORY HOMES",
          sqft: "Approx. 2,059 – 3,378 Sq. Ft.",
          bedbath: "4 – 5 Beds, 3 – 4.5 Baths",
          moreInfoLink: "https://sommersbend.com/collections/everview/",
        },
        component: Location,
        transitionImg: EverviewFlower,
        transitionBgColor: "#61818b",
        backgroundImgs: [Everview4, Everview3, Everview2, Everview1],
        audio: EverviewModelHomesVO,
        active: false,
      },
      {
        x: 400,
        y: 160,
        path: "/map/open-spaces",
        exact: true,
        title: "Open Spaces",
        component: Location,
        transition: "https://vimeo.com/538909153",
        transitionDarkTitle: true,
        backgroundImgs: [OpenSpaces3, OpenSpaces2, OpenSpaces1],
        backgroundDarkTitle: true,
        audio: OpenSpacesVO,
        active: true,
      },
      {
        x: 460,
        y: 550,
        path: "/map/canvas",
        exact: true,
        title: "Canvas",
        subtitle: "by Richmond American Homes",
        isNeighborhood: true,
        neighborhoodInfo: {
          type: "TWO-STORY HOMES",
          sqft: "Approx. 1,810 – 2,350 Sq. Ft.",
          bedbath: "3 – 4 Beds, 2.5 – 3 Baths",
          moreInfoLink: "https://sommersbend.com/collections/canvas/",
        },
        component: Location,
        transitionImg: CanvasFlower,
        transitionBgColor: "#7b586b",
        backgroundImgs: [Canvas1, Canvas2, Canvas3, Canvas4],
        audio: CanvasModelHomesVO,
        active: true,
      },
      {
        x: 260,
        y: 570,
        path: "/map/sports-park",
        exact: true,
        title: "Sports Park",
        component: Location,
        transition: "https://vimeo.com/538915521",
        backgroundImgs: [SportsPark1, SportsPark2, SportsPark3],
        audio: SportsParkVO,
        active: true,
      },
      {
        x: 910,
        y: 610,
        path: "/map/canopy",
        exact: true,
        title: "Canopy",
        subtitle: "by Woodside Homes",
        isNeighborhood: true,
        neighborhoodInfo: {
          type: "TWO-STORY HOMES",
          sqft: "Approx. 2,229 – 2,580 Sq. Ft.",
          bedbath: "3 – 6 Beds, 2.5 – 4 Baths",
          moreInfoLink: "https://sommersbend.com/collections/canopy/",
        },
        component: Location,
        transitionImg: CanopyLeaf,
        transitionBgColor: "#85552e",
        backgroundImgs: [Canopy1, Canopy2, Canopy3],
        audio: CanopyModelHomesVO,
        active: true,
      },
      {
        x: 980,
        y: 820,
        path: "/map/trails",
        exact: true,
        title: "Trails",
        component: Location,
        transition: TrailsTransition,
        transitionPoster: TrailsTransitionPoster,
        backgroundImgs: [Trails1, Trails2, Trails3],
        audio: TrailsVO,
        active: true,
      },
      {
        x: 730,
        y: 780,
        path: "/map/esplanade",
        exact: true,
        title: "Esplanade",
        subtitle: "by Taylor Morrison",
        isNeighborhood: true,
        neighborhoodInfo: {
          type: "ONE- AND TWO-STORY HOMES",
          ageQualified: true,
          sqft: "Approx. 1,787 – 2,574 Sq. Ft.",
          bedbath: "2 – 4 Beds, 2 – 3.5 Baths",
          moreInfoLink: "https://sommersbend.com/collections/esplanade/",
        },
        component: Location,
        transitionImg: EsplanadePlant,
        transitionBgColor: "#424692",
        backgroundImgs: [Esplanade1, Esplanade2, Esplanade3, Esplanade4],
        audio: EsplanadeModelHomesVO,
        active: true,
      },
      {
        x: 390,
        y: 840,
        path: "/map/esplanade-rec-center",
        exact: true,
        title: "Esplanade Resort Campus",
        component: Location,
        transitionImg: RecCenter4,
        transitionDarkTitle: true,
        backgroundImgs: [RecCenter4, RecCenter1, RecCenter2, RecCenter3],
        backgroundDarkTitle: true,
        audio: EsplanadeRecCenterVO,
        active: true,
      },
    ],
  },
  { path: "/finish", exact: true, title: "Finish", component: Finish },
]

export default routes
