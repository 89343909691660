import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import ReactPlayer from "react-player"

import routes from "../../routes"

import AudioBack from "../svg/AudioBack"
import AudioPause from "../svg/AudioPause"
import HelloSommersBend from "../../img/hello-sommers-bend-white.svg"
import Chevron from "../svg/Chevron"

import "./index.scss"

class Location extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      playing: true,
      paused: false,
      stopped: false,
    }
  }

  componentDidMount() {
    this.props.showVideos()
  }

  handlePlay = () => {
    this.setState(prevState => ({
      playing: !prevState.playing,
      paused: !prevState.paused,
      stopped: false,
    }))
  }

  handlePause = () => {
    this.setState({ playing: false, paused: true, stopped: false })
  }

  handleEnded = () => {
    this.setState({ playing: false, paused: false, stopped: false })
  }

  handleStop = () => {
    this.setState({ playing: false, paused: false, stopped: true })
    this.player.seekTo(0)
  }

  getNextStop = () => {
    const mapRoutes = routes[1].routes.filter(route => route.active)
    let matchIndex

    mapRoutes.forEach((route, index) => {
      if (route.title === this.props.title) {
        matchIndex = index
      }
    })

    return matchIndex < mapRoutes.length - 1 ? (
      <Link to={mapRoutes[matchIndex + 1].path}>
        Next Stop<span className="chevron">{Chevron}</span>
      </Link>
    ) : (
      <Link to="/finish">
        Finish Tour<span className="chevron">{Chevron}</span>
      </Link>
    )
  }

  ref = player => {
    this.player = player
  }

  render() {
    const nextStop = this.getNextStop()
    return (
      <div id="location" className="main-wrap">
        <div id="location-wrap">
          {this.props.isNeighborhood && this.props.neighborhoodInfo ? (
            <div id="neighborhood-info">
              <div className="type">{this.props.neighborhoodInfo.type}</div>
              <div className="info">
                {this.props.neighborhoodInfo.ageQualified ? "55+/Age Qualified" : ""}
                {this.props.neighborhoodInfo.ageQualified ? <br /> : ""}
                {this.props.neighborhoodInfo.sqft} <span className="hide-on-mobile"> | </span>
                <br className="show-on-mobile" /> {this.props.neighborhoodInfo.bedbath}
                {this.props.neighborhoodInfo.price ? <br /> : ""}
                {this.props.neighborhoodInfo.price ? this.props.neighborhoodInfo.price : ""}
              </div>
              <a
                href={this.props.neighborhoodInfo.moreInfoLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                MORE INFO
              </a>
            </div>
          ) : (
            ""
          )}
          <ReactPlayer
            ref={this.ref}
            id="audio-source"
            url={this.props.audio}
            playing={this.state.playing}
            onEnded={this.handleEnded}
          />
          <div id="audio-controls">
            <button
              id="audio-back"
              className={!this.state.playing && this.state.stopped ? "active" : ""}
              onClick={this.handleStop}
            >
              {AudioBack}
            </button>
            <button
              id="play-button"
              className={!this.state.playing ? "not-playing" : ""}
              onClick={this.handlePlay}
            >
              <div className="glow"></div>
              <div className="glow-alt"></div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265 265">
                <path
                  d="M132.5,203c39.41,0,71.36-31.55,71.36-70.47S171.91,62,132.5,62,61.14,93.58,61.14,132.5,93.09,203,132.5,203"
                  fill="#ffcf3f"
                />
                <polygon
                  points="111.53 166.77 169.52 132.6 111.53 98.83 111.53 166.77"
                  fill="#fff"
                />
              </svg>
            </button>
            <button
              id="audio-pause"
              className={!this.state.playing && this.state.paused ? "active" : ""}
              onClick={this.handlePause}
            >
              {AudioPause}
            </button>
          </div>
          <div id="location-nav">
            <Link to="/map" className="back">
              <span className="chevron">{Chevron}</span>Back to Map
            </Link>
            <Link to="/" className="nav-home">
              <img className="logo" src={HelloSommersBend} alt="Hello Sommers Bend" />
            </Link>
            {nextStop}
          </div>
        </div>
      </div>
    )
  }
}

export default Location
