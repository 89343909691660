import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import BackgroundImages from './BackgroundImages';

import './index.scss';

class VideoManager extends Component {

  componentWillUnmount() {
    if (this.timerID) {
      clearTimeout(this.timerID); 
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname && this.props.videos.find(video => video.transitionImg && video.path === this.props.location.pathname)) {
      this.timerID = setTimeout(() => {
        if (this.props.hideVideos) {
          this.props.hideVideos();
        }
      }, 2500);
    }
  }

  render() {
    const showVideos = this.props.location.pathname !== '/' && this.props.location.pathname !== '/map';
    return (
      <div className={`VideoManager ${this.props.type} ${showVideos ? 'show' : ''} ${this.props.isHidden && this.props.type === 'transition' ? 'hiding' : ''}`}>
        {this.props.videos ? this.props.videos.map((video, index) => (
          <div key={index} className={`full-screen-video-wrap ${this.props.type} ${video.path === this.props.location.pathname ? 'show' : ''} ${video.isNeighborhood ? 'isNeighborhood' : ''}`} style={video[this.props.type+'BgColor'] ? {backgroundColor: video[this.props.type+'BgColor']} : null}>
            {video[this.props.type+'Img'] || video[this.props.type+'Imgs']
              ? video[this.props.type+'Img'] ? <img className={video[this.props.type+'BgColor'] ? 'icon' : 'full-screen-img'} src={video[this.props.type+'Img']} onLoad={this.props.loadVideo(this.props.type, index)} alt={video.title}/> : <BackgroundImages images={video[this.props.type+'Imgs']} loadVideo={this.props.loadVideo} videoIndex={index} type={this.props.type}/>
              : <ReactPlayer
                className="full-screen-video"
                url={video[this.props.type]}
                loop={this.props.type === 'background'}
                onEnded={this.props.hideVideos ? this.props.hideVideos : null}
                volume={0}
                muted={true}
                playsinline={true}
                width="100%"
                height="100%"
                onReady={this.props.loadVideo(this.props.type, index)}
                config={video[`${this.props.type}Poster`] ? { file: {
                  attributes: {
                    poster: video[`${this.props.type}Poster`]
                  }
                }} : undefined}
                playing={video[`${this.props.type}Loaded`] && video.path === this.props.location.pathname}/>
            }
            {video.isNeighborhood && this.props.type === 'transition' ? <h1 className={`location-title ${video[this.props.type+'DarkTitle'] ? 'dark' : ''}`}>{video.title}</h1> : <h1 className={`location-title ${video[this.props.type+'DarkTitle'] ? 'dark' : ''}`}>{video.title}{video.subtitle ? <span>{video.subtitle}</span> : ''}</h1>}
          </div>
        )) : ''}
      </div>
    )
  }
}

export default VideoManager;