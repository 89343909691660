import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import ReactPlayer from 'react-player';

import SommersBend from '../../img/sommers-bend.svg';

import './index.scss';

class Finish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false
    }
  }

  loaded = () => {
    this.setState({ loaded: true });
  }

  error = (e) => {
    this.setState({ error: true });
  }
  
  render() {

    return (
      <div id="finish-wrap" className="main-wrap">
        <div className="header-logo"><Link to="/"><img src={SommersBend} alt="Sommers Bend" /></Link></div>
        <div id="finish">
          {/* <ReactPlayer className="full-screen-video" url="https://vimeo.com/528553640" loop={true} volume={0} muted={true} playsinline={true} onReady={this.loaded} onError={this.error} playing={this.state.loaded}  width="100%" height="100%"/> */}
          <div className="logo"><img src={SommersBend} alt="Somers Bend"/></div>
          <div className="thanks">See you soon!</div>
          <div className="links">
            <a href="https://sommersbend.com" target="_blank" rel="noopener noreferrer">Visit Website</a><br/>
            <Link to="/map">Back to Map</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Finish;
