import React, { Component } from 'react';

import './index.scss';

class BackgroundImages extends Component {

  constructor(props) {
    super(props);
    this.state = {
      imagesLoaded: props.images.map(image => false)
    }
  }

  imageLoaded = (index) => () => {
    let imagesLoaded = [...this.state.imagesLoaded];
    imagesLoaded[index] = true;
    if(!imagesLoaded.includes(false)) {
      // this.props.loadVideo() returns a function to be run so we need to run it with () afterwards
      this.props.loadVideo(this.props.type, this.props.videoIndex)();
    }
    this.setState({imagesLoaded});
  }

  render() {
    return (
      <div className={`BackgroundImages children-${this.props.images.length}`}>{
        this.props.images.map((img, index) => <img key={index} className="full-screen-img" src={img} onLoad={this.imageLoaded(index)} alt=""/>)
      }</div>
    )
  }
}

export default BackgroundImages;