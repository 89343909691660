import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
import VideoManager from '../../components/VideoManager';
import routes from '../../routes';
import './index.scss';

const videoRoutes = routes[1]['routes'];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: videoRoutes.map(route => ({
        path: route.path,
        background: route.background ? route.background : false,
        transition: route.transition ? route.transition : false,
        transitionPoster: route.transitionPoster ? route.transitionPoster : false,
        backgroundPoster: route.backgroundPoster ? route.backgroundPoster : false,
        backgroundImg: route.backgroundImg ? route.backgroundImg : false,
        backgroundImgs: route.backgroundImgs ? route.backgroundImgs : false,
        backgroundDarkTitle: route.backgroundDarkTitle ? route.backgroundDarkTitle : false,
        transitionImg: route.transitionImg ? route.transitionImg : false,
        transitionDarkTitle: route.transitionDarkTitle ? route.transitionDarkTitle : false,
        transitionBgColor: route.transitionBgColor ? route.transitionBgColor : false,
        title: route.title,
        subtitle: route.subtitle ? route.subtitle : false,
        isNeighborhood: route.isNeighborhood ? route.isNeighborhood : false,
        backgroundLoaded: false,
        transitionLoaded: false,
      })),
      startLoadingVideos: false,
      videosHidden: false
    }
  }

  startLoadingVideos = () => {
    this.setState({startLoadingVideos: true});
  }

  loadVideo = (type, index) => () => {
    // 1. Make a shallow copy of the items
    let videos = [...this.state.videos];
    // 2. Make a shallow copy of the item you want to mutate
    let video = {...videos[index]};
    // 3. Replace the property you're intested in
    video[`${type}Loaded`] = true;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    videos[index] = video;
    // 5. Set the state to our new copy
    this.setState({videos});
  }

  hideVideos = () => {
    this.setState({videosHidden: true});
  }

  showVideos = () => {
    this.setState({videosHidden: false});
  }

  render() {
    const backgroundVideosLoaded = this.state.videos.filter(video => video['backgroundLoaded']).length;
    const transitionVideosLoaded = this.state.videos.filter(video => video['transitionLoaded']).length;
    const videoLoadPercent = (backgroundVideosLoaded + transitionVideosLoaded) / (this.state.videos.length * 2);
    return (
      <Router>
        <div id="app">
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(routeProps) => <route.component startLoadingVideos={this.startLoadingVideos} videoLoadPercent={videoLoadPercent} showVideos={this.showVideos} {...routeProps} routes={route.routes} {...route}/>}
            />
          ))}
          {this.state.startLoadingVideos ?
            <Route
              path="/"
              render={(routeProps) => <VideoManager type="transition" isHidden={this.state.videosHidden} hideVideos={this.hideVideos} loadVideo={this.loadVideo} videos={this.state.videos} {...routeProps}/>}
            />
          : ''}
          {this.state.startLoadingVideos ?
            <Route
              path="/"
              render={(routeProps) => <VideoManager type="background" loadVideo={this.loadVideo} videos={this.state.videos} {...routeProps}/>}
            />
          : ''}
        </div>
      </Router>
    )
  }
}

export default App;
