import React, { Component } from "react"
import { Link, Route, Redirect } from "react-router-dom"

import SommersBend from "../../img/sommers-bend.svg"
import MapImg from "../../img/map/sommers-bend-map.jpg"

import "./index.scss"

class DrivingMap extends Component {
  constructor() {
    super()
    this.state = { mapLoaded: false }
  }

  renderDots = () => {
    return this.props.routes.map((route, index) => (
      <g
        key={index}
        className={!route.active && "cursor-default"}
        onClick={() => (route.active ? this.props.history.push(route.path) : {})}
      >
        <circle cx={route.x} cy={route.y} r="32" />
        <text x={route.x} y={route.y} textAnchor="middle" transform="translate(0 12)">
          {index + 1}
        </text>
      </g>
    ))
  }

  placeholderSrc = (width, height) =>
    `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`

  renderListings = () => {
    return this.props.routes.map((route, index) => (
      <li key={index}>
        <Link to={route.path}>
          <span className="number">{index + 1}</span> <span className="title">{route.title}</span>
        </Link>
      </li>
    ))
  }

  renderRoutes = () => {
    const routes = this.props.routes

    return routes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        render={routeProps => (
          <route.component showVideos={this.props.showVideos} {...routeProps} {...route} />
        )}
      />
    ))
  }

  render() {
    const mapDots = this.renderDots()
    const routes = this.renderRoutes()
    const listMaxIndex = 4
    return this.props.videoLoadPercent === 1 ? (
      <div id="driving-map">
        {this.props.location.pathname === "/map" ? (
          <div id="map" className="main-wrap">
            <div className="header-logo">
              <Link to="/">
                <img src={SommersBend} alt="Sommers Bend" />
              </Link>
            </div>
            <div id="map-wrap">
              <img
                className="map-img"
                src={this.placeholderSrc(1082, 988)}
                alt="placeholder"
                style={this.state.mapLoaded ? { display: "none" } : {}}
              />
              <img
                className="map-img"
                alt="map of Sommers Bend"
                style={this.state.mapLoaded ? {} : { display: "none" }}
                src={MapImg}
                onLoad={() => this.setState({ mapLoaded: true })}
              />
              <svg id="map-dots" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 991.6168">
                {mapDots}
              </svg>
            </div>
            <div id="listings-wrap">
              <ul className="listings">
                {this.props.routes.map((route, index) =>
                  index <= listMaxIndex ? (
                    <li key={index}>
                      {route.active ? (
                        <Link to={route.path}>
                          <span className="number">{index + 1}</span>{" "}
                          <span className="title">{route.title}</span>
                        </Link>
                      ) : (
                        <>
                          <span className="number">{index + 1}</span>
                          <span className="title not-active">{route.title}</span>
                        </>
                      )}
                    </li>
                  ) : null
                )}
              </ul>
              <ul className="listings">
                {this.props.routes.map((route, index) =>
                  index > listMaxIndex ? (
                    <li key={index}>
                      <Link to={route.path}>
                        <span className="number">{index + 1}</span>{" "}
                        <span className="title">{route.title}</span>
                      </Link>
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          </div>
        ) : (
          routes
        )}
      </div>
    ) : (
      <Redirect to={{ pathname: "/", search: encodeURI(this.props.location.pathname) }} />
    )
  }
}

export default DrivingMap
