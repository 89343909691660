import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import ReactPlayer from 'react-player';

import HelloSommersBend from '../../img/hello-sommers-bend.svg';
import SommersBendLogo from '../../img/sommers-bend-logo.svg';
import Button from '../../img/button.svg';
import './index.scss';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false
    }
  }

  loaded = () => {
    this.setState({ loaded: true });
  }

  error = (e) => {
    this.setState({ error: true });
    this.props.startLoadingVideos();
  }

  componentDidMount() {
    this.props.startLoadingVideos();
  }

  render() {
    let start = <div id="start"><img src={Button} alt="Hello Sommers Bend" /><span>READY</span></div>
    if (this.props.videoLoadPercent > 0.79) {
      start = <div id="start"><img src={Button} alt="Hello Sommers Bend" /><span>SET</span></div>
    }
    
    if (this.props.videoLoadPercent === 1) {
      let to = this.props.location.search ? this.props.location.search.replace('?', '') : '/map';
      start = <Link to={to} id="start"><img src={Button} alt="Hello Sommers Bend" /><span>START TOUR</span></Link>
    }
    return (
      <div id="home" className="main-wrap">
        <div id="hero-wrap">
          <img className="hello" src={HelloSommersBend} alt="Hello Sommers Bend" />
          <img className="logo" src={SommersBendLogo} alt="Sommers Bend logo" />
        </div>
        {start}
      </div>
    )
  }
}

export default Home;
